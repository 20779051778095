<script setup lang="ts">
    // @ts-ignore
    import { useI18n } from 'vue-i18n';
    import useNavigationStore from '@/stores/ui/navigation'
    import Link from '@/views/components/Link.vue'
    import { Ref, onMounted, onUnmounted,ref, watch } from 'vue'
    import { navigate } from 'vike/client/router'
    import { usePageContext } from 'vike-vue/usePageContext'

    // Local variables
    const { t } = useI18n()
    console.log(t("w_fct_header_proAndPrices"))

    // @ts-ignore
    const VITE_ASSET_URL = import.meta.env.VITE_ASSET_URL
    const sections = ['home', 'app-features','pro-user-to', 'client-user', 'contact']

    // Refs
    const activeSection: Ref<string | null> = ref(null)
    const observer:any = ref(null)
    const pageContext = usePageContext()
    
    const options = {
      root: null, 
      rootMargin: '0px', 
      threshold: 0.5
    };

    // Stores
    const navigationStore = useNavigationStore()

    // Lifecycle hooks
    onMounted(() => {
        console.log("pageContext", pageContext)
        console.log("pageContext.urlPathname", pageContext.urlPathname)

        window.addEventListener('scroll', () => {
            sections.forEach(section => {
                if (isActive(section)) {
                    activeSection.value = section
                } 
            })
        })

        // Create a new Intersection Observer
        observer.value = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    activeSection.value = entry.target.id
                }
            });
        }, options
        );

        // Observe each div item
        sections.forEach(itemId => {
            const item = document.getElementById(itemId);
            if (item) {
                observer.value.observe(item);
            }
        });
    })

    // Unobserve each div item
    const unobserveItems = () => {
      sections.forEach(itemId => {
        const item = document.getElementById(itemId);
        if (item) {
          observer.value.unobserve(item);
        }
      });
    }

    onUnmounted(() => {
        unobserveItems();
        window.removeEventListener('scroll', () => {})
    })

    // Functions
    const scrollToSection = async (id: string) => {
        if (pageContext.urlPathname !== '/') {
            navigationStore.setHomeScrollTo(id)
            await navigate('/')
        } else {
            const section = document.getElementById(id !== "client-user"? id : id+'-replace')
            if (section) {
                activeSection.value = id
                setTimeout(() => {
                section.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                })}, 300);
            } 
        }
    };

     watch(() => navigationStore.homeScrollTo, (newId) => {
        let id = newId 
        if (id && id === "home") {
            if (pageContext.urlPathname === '/') {
                const section: any = document.getElementById(id)
                if (section) {
                    setTimeout(() => {
                        section.scrollIntoView({
                            behavior: "smooth"
                        })
                        activeSection.value = id
                        navigationStore.setHomeScrollTo(null)
                    }, 500);
                }
            }
        } 
        let headerId = navigationStore.headerScrollTo;
        if (headerId && headerId === "pro-user-to" || headerId === "home" || headerId === "contact") {
            activeSection.value = headerId
        }
    });


    const isActive = (section: string) => {
        const element = document.getElementById(section);
        const rect = element?.getBoundingClientRect();

        if (rect) {
            const windowHeight = window.innerHeight || document.documentElement.clientHeight;
            
            // Check if the section is at least 30% visible in the viewport
            const isVisible = (rect.top <= windowHeight * 0.7) && (rect.bottom >= 0);
            return isVisible;
        } else {
            return false;
        }
    };
</script>

<template>
    <!-- Header Section Start -->
    <div>
        <nav
            id="header-public"
            class="navbar navbar-expand-md bg-inverse position-fixed top-0 start-0 scrolling-navbar menu-bg"
            style="background-color: #233648;">
            <div class="container">
                <Link
                    href="/" class="navbar-brand">
                    <img :src="VITE_ASSET_URL + 'assets/public/assets/img/logo.webp'" alt="Logo topela">
                </Link>
                <button 
                    class="navbar-toggler" 
                    type="button" 
                    data-bs-toggle="collapse" 
                    data-bs-target="#navbarCollapse"
                    aria-controls="navbarCollapse" 
                    aria-expanded="false" 
                    aria-label="Toggle navigation">
                    <i class="lni-menu"></i>
                </button>
                <div class="collapse navbar-collapse" id="navbarCollapse">
                    <ul class="navbar-nav mr-auto w-100 justify-content-end">
                        <li class="nav-item">
                            <a 
                                class="nav-link" 
                                :class="{ active: activeSection === 'home' }" 
                                href="/#home" @click.prevent="scrollToSection('home')">
                                {{ t('w_fct_header_home') }}
                            </a>
                        </li>
                        <li class="nav-item">
                            <a 
                                class="nav-link" 
                                :class="{ active: activeSection === 'app-features' }" 
                                href="/#app-features" 
                                @click.prevent="scrollToSection('app-features')">
                                {{ t('w_fct_header_presentation') }}
                            </a>
                        </li>
                        <li class="nav-item">
                            <a 
                                class="nav-link" 
                                :class="{ active: activeSection === 'pro-user-to' }" 
                                href="/#pro-user-to" 
                                @click.prevent="scrollToSection('pro-user-to')"
                            >
                              {{ t('w_fct_header_proAndPrices') + " | " + t("w_fct_header_pricing") }}
                            </a>
                        </li>
                        <li class="nav-item">
                            <a 
                                class="nav-link" 
                                :class="{ active: activeSection === 'client-user' }" 
                                href="/#client-user" 
                                @click.prevent="scrollToSection('client-user')">
                                {{ t('w_fct_header_individual') }}
                            </a>
                        </li>
                        
                        <li class="nav-item">
                            <a 
                                class="nav-link" 
                                :class="{ active: activeSection === 'contact' }" 
                                href="/#contact" 
                                @click.prevent="scrollToSection('contact')">
                                {{ t('w_fct_header_contact') }}
                            </a>
                        </li>
                        <li class="nav-item ms-2 d-none d-sm-none d-md-block d-lg-block d-xl-block">
                            <div class="btn-group">
                                <Link
                                    href="/auth/login"
                                    class="btn btn-primary btn-sm rounded-0 m-0 ml-2 p-2"
                                    type="button">
                                    {{ t('w_fct_header_clientSpace') }}
                                </Link>
                            </div>
                        </li>
                        <li class="nav-item ms-3 d-block d-sm-block d-md-none d-lg-none d-xl-none mt-2">
                            <div class="btn-group">
                                <Link
                                    href="/auth/login"
                                    class="btn btn-primary btn-sm rounded-0 m-0 ml-2 p-2" 
                                    type="button">
                                    {{ t('w_fct_header_clientSpace') }}
                                </Link>
                                <button v-if="false" 
                                    class="btn btn-secondary btn-sm dropdown-toggle rounded-0 m-0 ml-2 p-2" 
                                    type="button"
                                    data-toggle="dropdown" 
                                    aria-haspopup="true" 
                                    aria-expanded="false">
                                    {{ t('w_txt_signIn') }}
                                </button>
                                <div v-if="false" 
                                    class="dropdown-menu p-0">
                                    <a 
                                        class="dropdown-item bg-warning text-white p-2" 
                                        href="cs/home">
                                        {{ t('w_fct_header_clientSpace') }}
                                    </a>
                                    <a 
                                        class="dropdown-item bg-info text-white p-2" 
                                        href="#">
                                        {{ t('w_fct_header_workerSpace') }}
                                    </a>
                                </div>
                            </div>

                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
    <!-- Header Section End -->
</template>

<style scoped>
@import url('@/assets/css/bootstrap-extended.css');
@import url('@/assets/css/animate.css');
@import url('@/assets/css/main.css');
@import url('@/assets/css/responsive.css');
.navbar {
    right: 0px;
    z-index: 1030;
}
.ms-2 {
    margin-left: 2rem !important; 
}
@media only screen and (min-width: 600px) {
    .navbar {
        /* right: 17px; */
        right: 1vw;
        width: 99vw;
        z-index: 1030;
    }
}
</style>